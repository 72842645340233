<template>
    <div class="mapContainer">
        <el-amap ref="map" :center="center" :zoom="zoom">
            <el-amap-marker v-for="marker in markers" :position="marker.position" :key="marker.id" :icon="marker.icon"
                :label="marker.label" :events="{ // 事件监听
                    init(m) {
                        // 事件监听
                        m.on('click', (e) =>
                            viewDetail(e)
                        )
                    }
                }"></el-amap-marker>


        </el-amap>
        <el-dialog title="提示" ref="input" :visible.sync="dialogVisible" width="20%" :modal="false"
            :before-close="handleClose">
            <p  style="text-align: center;font-size: 25px;">{{ yanzhengHomeName }}</p>
            <p style="text-align: center;font-size: 25px;">请输入验证码</p>
            <el-input ref="input2" v-model="input" @keyup.enter="submitInput" placeholder="请输入验证码"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitInput">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import icon from '../assets/img/icon2.png'
export default {
    data() {
        return {
            yanzhengHomeName:'',
            dialogVisible: false,
            input: '',
            homeInfo: '',
            center: [118.172452, 24.510275],
            zoom: 14,
            // 标记点数组
            markers: [],
            windows: [],
            window: '',
            pointMarker: [
                {
                    id: 10,
                    lat: 24.520388,
                    lng: 118.184575,
                    label: {
                        content: '围里1716',
                    }
                },
                {
                    id: 11,
                    lat: 24.531400,
                    lng: 118.184575,
                    label: {
                        content: '围里376',
                    }
                },
                {
                    id: 12,
                    lat: 24.531400,
                    lng: 118.174575,
                    label: {
                        content: '围里1267',
                    }
                }
                ,
                {
                    id: 13,
                    lat: 24.531400,
                    lng: 118.164575,
                    label: {
                        content: '高崎5051',
                    }
                }
                ,
                {
                    id: 14,
                    lat: 24.531400,
                    lng: 118.154675,
                    label: {
                        content: '寨上1264',
                    }
                }
            ],
        }
    },
    created() {
        document.onkeyup = e => {
            if (e.keyCode === 13 && this.dialogVisible === true) {
                this.submitInput()
                
            }
        }

    },
    mounted() {
        this.point(this.pointMarker)

    },
    computed: {
    },
    methods: {
        // 地图坐标
        point(pointMarker) {
            let markers = [];
            let that = this;
            pointMarker.forEach((item, index) => {
                markers.push({
                    label: {
                        content: item.label.content,
                        offset: [5, -26]
                    },
                    position: [item.lng, item.lat],
                    icon: icon, //不设置默认蓝色水滴
                })

            })
            //添加点标注
            this.markers = markers;
            console.log(markers);
        },
        viewDetail(e) {
            this.yanzhengHomeName = e.target.De.label.content
            console.log(e.target.De.label.content)
            console.log(e.target.De.position.lat)
            console.log(e.target.De.position.lng)
            this.pointMarker.forEach((item, index) => {
                this.dialogVisible = true

                if (item.lat.toFixed(6) == e.target.De.position.lat && item.lng.toFixed(6) == e.target.De.position.lng) {
                    this.homeInfo = item
                    // this.$router.push({
                    //     path: '/detail',
                    //     // query: {
                    //     //     homeInfo: item
                    //     // }
                    // })
                    // this.dialogVisible = false
                    localStorage.setItem('homeInfo', JSON.stringify(item))
                    console.log('this.input', this.$refs.input.$children);
                    this.$nextTick(() => {
                        // this.$refs.input.$children[2].$el.focus()
                        this.$refs.input2.$el.querySelector('input').focus()
                        console.log('this.inputnext', this.$refs.input.$children[2]);
                        console.log('this.input2', this.$refs.input2.$el.querySelector('input'));
                    });
                }
            })
        },
        // 关闭弹出框
        handleClose(done) {
            this.dialogVisible = false
            this.input = ''
        },
        // 提交用户输入的验证码
        submitInput() {
            if(this.homeInfo.id===9){
                this.$message({
                        message: '本住宅暂未开放',
                        type: 'warning'
                    });
                this.dialogVisible = false
                this.input = ''
                return
            }
            this.$http.get(`Home/GetHousePwd?houseId=${this.homeInfo.id}&housePwd=${this.input}`).then(res => {
                console.log('res', res);
                if (res.data) {
                    this.$router.push({
                        path: '/detail',
                    })
                } else {
                    this.$message({
                        message: '验证码输入错误，请重新输入',
                        type: 'warning'
                    });

                }
                this.dialogVisible = false
                this.input = ''
                console.log(res);
                console.log('res');
            }).catch((error) => {
                if(error.response.data.status == 500) {
                    this.$message({
                        message: '验证码不能为空',
                        type: 'info'
                    });
                    console.log('error',error);
                }
            })
            console.log(this.input);
            console.log('this.input');

        }
    }
}
</script>
<style lang="scss" scoped>
.mapContainer {
    width: 100%;
    height: 99%;
}

.mapContainer .amap-marker-label {
    color: #fcaf17;
}

.el-dialog h3 {
    margin-bottom: 1rem;
    text-align: center;
}
</style>
